import React, { useState, useEffect, useRef, useContext } from 'react';
import useWebSocket from 'react-use-websocket';
import humps from 'humps';
import { UserContext } from '../../UserContext';
import { useNavigate, useParams } from 'react-router-dom';
import Panel from '../../components/Panel';
import {
  Paper,
  Box,
  TextField,
  IconButton,
  Radio,
  Drawer,
  Grid,
  Alert,
  List,
  ListItem,
  ListItemText,
  Divider,
  Snackbar,
} from '@mui/material';
import { styled } from '@mui/system';
import BottomPanel from '../../components/BottomPanel';
import NavigationBar from '../../components/NavigationBar';
import { navigationBarHeight } from '../../components/Layout';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import {
  TitleLabel,
  SubTitleLabel,
  Label,
  SuccessLabel,
} from '../../components/Label';
import CloseIcon from '@mui/icons-material/Close';
import NumberFormat from 'react-number-format';
import { ProductAPI } from '../../apis/ProductAPI';
import { OrderAPI } from '../../apis/OrderAPI';
import { getWebsocketToken } from '../../apis/configs/axiosConfigs';
import Dialog from '../../components/Dialog';
import StyledButton from '../../components/StyledButton';
import Invoice from '../../components/trade/Invoice';
import {
  RoundUp,
  RoundDown,
  CountDecimals,
  ThousandSeparator,
  ReplaceCommaToNumber,
  ReplaceCommaToInt,
  ReplaceStringToInt,
  ReplaceStringToNumber,
  CheckLeadingZero,
  PriceNumberFormat,
} from '../../utils/CoinNumberFormat';
import { UserAPI } from '../../apis/UserAPI';
import useInvoice from '../../hooks/useInvoice';
import validator from '../account/validator';
import { notifyOpenBrower } from '../../utils/WebViewCallback';
import { SuccessStatusBox } from '../../components/StatusBox';

const StyledBox = styled(Box)({
  backgroundColor: '#ff8f08',
  borderRadius: '5px',
  width: '40px',
  height: '32px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '5px',
});

const paymentTitles = {
  balance: '餘額扣款',
  bank: '銀行轉帳',
  store: '台灣大哥大門市',
  dcb: '大哥付',
};

const selectedBorder = {
  borderColor: '#FF8E15',
  borderWidth: '2px',
};

const sxSelected = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': selectedBorder,
    '&:hover fieldset': selectedBorder,
    '&.Mui-focused fieldset': selectedBorder,
    color: '#FF8F08',
  },
};

const sxUnselected = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#eee',
      borderWidth: '2px',
      color: '#000',
    },
  },
};

const purchaseStatements = {
  balance:
    '一旦點擊確定即表示您同意以上交易內容，若確認過程中因幣價波動過大，OP加密資產存摺保留取消此筆交易的權利。',
  bank: '請在訂單成立後10分鐘內完成付/匯款，以保留訂單交易價格；若加密貨幣價格波動過大，或在下單10分鐘後才付款，OP加密資產存摺保留取消交易或以當時市場價格重新計算交易價格之權利。',
  store:
    '請在訂單成立後10分鐘內完成付/匯款，以保留訂單交易價格；若加密貨幣價格波動過大，或在下單10分鐘後才付款，OP加密資產存摺保留取消交易或以當時市場價格重新計算交易價格之權利。',
};

export default function BuyPage() {
  const { user } = useContext(UserContext);
  const { getDonee, getCarrierType, getCarrierCode } = useInvoice();
  const navigate = useNavigate();
  const params = useParams();
  const productId = params.coin;
  const initData = useRef(false);
  const [product, setProduct] = useState(null);
  const [paymentInfo, setPaymentInfo] = useState([]);
  const buyPriceRef = useRef({
    balance: 0,
    bank: 0,
    store: 0,
    dcb: 0,
  });
  const [errors] = useState({});
  const [expired, setExpired] = useState(false);
  const [timeLeft, setTimeLeft] = useState(10);
  const [size, setSize] = useState(0);
  const sizeRef = useRef(0);
  const priceRef = useRef(0);
  const websocketPriceRef = useRef(0);
  const [sizeFocus, setSizeFocus] = useState(false);
  const [price, setPrice] = useState(0);
  const sizeFocusRef = useRef(false);
  const [priceFocus, setPriceFocus] = useState(true);
  const priceFocusRef = useRef(true);
  const expiredIdRef = useRef(null);
  const timeLeftIdRef = useRef(false);
  const [disableButton, setDisableButton] = useState(true);
  const [alert, setAlert] = useState(false);
  const [paymentValue, setPaymentValue] = useState('balance');
  const paymentBtnRef = useRef('balance');
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const alertTitle = useRef(null);
  const alertMessage = useRef(null);
  const alertActionLabel = useRef(null);
  const [tab, setTab] = useState(0);
  const billInfoDataRef = useRef(null);
  const carrierRef = useRef(getCarrierType('A'));
  const phoneBarcodeRef = useRef(null);
  const citizenDigitalRef = useRef(null);
  const cityRef = useRef(null);
  const districtRef = useRef(null);
  const addressRef = useRef(null);
  const doneeRef = useRef(null);
  const doneeCodeRef = useRef(null);
  const zipCodeRef = useRef(null);
  const billNameRef = useRef(null);
  const invoiceTabRef = useRef(0);
  const balance = useRef(0);
  const balanceAvailableRef = useRef(true);
  const bankAvailableRef = useRef(true);
  const storeRemainingRef = useRef(0);
  const storeMaxAmountRef = useRef(0);
  const storeAvailableRef = useRef(true);
  const dcbMaxAmountRef = useRef(0);
  const dcbAvailableRef = useRef(true);
  const [invoiceInputFocus, setInvoiceInputFocus] = useState(false);
  const maxFund = Number(product?.maxFund);
  const minFund = Number(product?.minFund);
  const lastPriceRef = useRef(0);
  const [snackbar, setSnackbar] = useState({
    open: false,
    vertical: 'bottom',
    horizontal: 'center',
  });
  const { vertical, horizontal, open } = snackbar;

  const bottomDrawerTitle = [
    {
      title: '付款方式：',
      value: (
        <TitleLabel fontSize="15px">{paymentTitles[paymentValue]}</TitleLabel>
      ),
    },
    {
      title: '報價：',
      value: (
        <TitleLabel fontSize="15px">
          1&nbsp;{product?.baseCurrencyId}
          &nbsp;&nbsp;≈&nbsp;&nbsp;
          <NumberFormat
            value={buyPriceRef.current[paymentValue]}
            displayType={'text'}
            thousandSeparator={true}
            decimalScale={buyPriceRef.current[paymentValue] < 100 ? 2 : 0}
            fixedDecimalScale={true}
          />
          &nbsp;&nbsp;TWD
        </TitleLabel>
      ),
    },
    {
      title: '交易金額：',
      value: (
        <TitleLabel fontSize="15px">
          <NumberFormat
            value={price}
            displayType={'text'}
            thousandSeparator={true}
            decimalScale={0}
            fixedDecimalScale={true}
          />
          &nbsp;&nbsp;TWD
        </TitleLabel>
      ),
    },
    {
      title: '購買數量：',
      value: (
        <TitleLabel fontSize="15px">
          <NumberFormat
            value={size}
            displayType={'text'}
            thousandSeparator={true}
            decimalScale={
              product?.baseIncrement ? CountDecimals(product?.baseIncrement) : 0
            }
          />
          &nbsp;&nbsp;
          {product?.baseCurrencyId}
        </TitleLabel>
      ),
    },
  ];

  const { lastMessage } = useWebSocket(process.env.REACT_APP_CEX_PRICE_WS, {
    share: true,
    shouldReconnect: () => true,
    reconnectInterval: 3000,
    queryParams: {
      token: getWebsocketToken(),
    },
  });

  // fetch data for page usage
  useEffect(() => {
    async function fetchProductData() {
      const product = await ProductAPI.getProduct(productId);
      setProduct(product);
      priceRef.current = product?.minFund;
      setPrice(ThousandSeparator(product?.minFund));
      const newSize =
        product?.minFund / product?.realtimePrice[paymentValue].buyPrice;
      const roundedSize = RoundDown(
        newSize,
        CountDecimals(product?.baseIncrement),
      );
      sizeRef.current = roundedSize;
      setSize(roundedSize);

      const priceData = (paymentType) =>
        product?.realtimePrice[paymentType].buyPrice;

      buyPriceRef.current = {
        balance: priceData('balance'),
        bank: priceData('bank'),
        store: priceData('store'),
        dcb: priceData('dcb'),
      };
      setDisableButton(false);
    }

    async function fetchPaymentInfo() {
      const paymentInfo = await UserAPI.getPaymentInfo(productId, 'buy');
      setPaymentInfo(paymentInfo);
      for (let i = 0; i < paymentInfo.length; i++) {
        if (paymentInfo[i].payment === 'balance') {
          balance.current = parseInt(
            paymentInfo[i].remainingAmount,
          ).toLocaleString();
          balanceAvailableRef.current = paymentInfo[i].available;
        }
        if (paymentInfo[i].payment === 'bank') {
          bankAvailableRef.current = paymentInfo[i].available;
        }
        if (paymentInfo[i].payment === 'store') {
          storeRemainingRef.current = parseInt(
            paymentInfo[i].remainingAmount,
          ).toLocaleString();
          storeMaxAmountRef.current = parseInt(
            paymentInfo[i].maxAmount,
          ).toLocaleString();
          storeAvailableRef.current = paymentInfo[i].available;
        }
        if (paymentInfo[i].payment === 'dcb') {
          dcbMaxAmountRef.current = parseInt(
            paymentInfo[i].maxAmount,
          ).toLocaleString();
          dcbAvailableRef.current = paymentInfo[i].available;
        }
      }
      if (checkPaymentEnabled(paymentType[0].payment)) {
        setPaymentValue('balance');
        paymentBtnRef.current = 'balance';
      } else if (checkPaymentEnabled(paymentType[1].payment)) {
        setPaymentValue('bank');
        paymentBtnRef.current = 'bank';
      } else if (checkPaymentEnabled(paymentType[2].payment)) {
        setPaymentValue('store');
        paymentBtnRef.current = 'store';
      } else if (checkPaymentEnabled(paymentType[3].payment)) {
        setPaymentValue('dcb');
        paymentBtnRef.current = 'dcb';
      }
    }

    async function fetchBillInfoData() {
      const userData = await UserAPI.getBillInfo();
      billInfoDataRef.current = userData;
      if (userData.isDonee) {
        invoiceTabRef.current = 1;
        setTab(1);
      }
      if (userData.carrierType) {
        carrierRef.current = getCarrierType(userData.carrierType);
        phoneBarcodeRef.current = userData.phoneBarcode;
        citizenDigitalRef.current = userData.citizenDigitalCertificate;
      }
      cityRef.current = userData.billAddrCity;
      districtRef.current = userData.billAddrDistrict;
      addressRef.current = userData.billAddr;
      billNameRef.current = userData.billInfoName;
      doneeRef.current = userData.doneeName;
      doneeCodeRef.current = userData.doneeCode;
    }

    if (initData.current === false) {
      invoiceTabRef.current = 0;
      initData.current = true;
      fetchProductData();
      fetchBillInfoData();
      fetchPaymentInfo();
    }
  }, []);

  useEffect(() => {
    if (lastMessage) {
      const data = JSON.parse(lastMessage?.data);
      if (data['id'] && data['status']) {
        return;
      }

      const realtimePrices = data;

      const updateProductRealtimePrice = (realtimePrice) => {
        const newProduct = { ...product };
        newProduct.realtimePrice = humps.camelizeKeys(realtimePrice);
        setProduct(newProduct);
      };

      if (product) {
        // to avoid using init product price when payment method change
        updateProductRealtimePrice(realtimePrices[product.id]);
      }

      const priceData = (paymentType) =>
        realtimePrices[product.id][paymentType].buy_price;

      websocketPriceRef.current = {
        balance: priceData('balance'),
        bank: priceData('bank'),
        store: priceData('store'),
        dcb: priceData('dcb'),
      };

      if (!openDrawer) {
        buyPriceRef.current = websocketPriceRef.current;
        if (sizeFocus) {
          handleSizeFocus();
        }
        if (priceFocus) {
          handlePriceFocus();
        }
        // 如果價格更新，選中的付費方式價格不符合限制，若還有其他付費方式，則自動更換付費方式
        if (!disableButton) {
          if (paymentValue === 'balance') {
            if (
              !checkPaymentEnabled('balance') &&
              checkPaymentEnabled('bank')
            ) {
              paymentBtnRef.current = 'bank';
              setPaymentValue('bank');
            }
          }
          if (paymentValue === 'bank') {
            if (!checkPaymentEnabled('bank') && checkPaymentEnabled('store')) {
              paymentBtnRef.current = 'store';
              setPaymentValue('store');
            }
          }
          if (paymentValue === 'store') {
            if (
              !checkPaymentEnabled('store') &&
              checkPaymentEnabled('balance')
            ) {
              paymentBtnRef.current = 'balance';
              setPaymentValue('balance');
            }
          }
        }
      }
    }
  }, [lastMessage, sizeFocus, priceFocus, openDrawer]);

  const checkBillInfo = () => {
    let alert = null;
    if (invoiceTabRef.current === 1) {
      if (!doneeCodeRef.current || !doneeRef.current) {
        alert = '請選擇捐贈單位';
      }
    } else {
      if (getCarrierCode(carrierRef.current) === 'P') {
        if (!cityRef.current || !districtRef.current || !addressRef.current) {
          alert = '請輸入通訊地址';
        }
      }
      if (getCarrierCode(carrierRef.current) === 'B') {
        if (!validator.isPhoneBarcode(phoneBarcodeRef.current, errors)) {
          alert = errors.barcode;
        }
      }
      if (getCarrierCode(carrierRef.current) === 'C') {
        if (!validator.isCitizenDigital(citizenDigitalRef.current, errors)) {
          alert = errors.citizenDigital;
        }
      }
    }

    if (alert) {
      alertTitle.current = '請輸入完整發票資料';
      alertMessage.current = alert;
      alertActionLabel.current = '確定';
      setShowAlert(true);
    } else {
      expiredIdRef.current = setTimeout(() => setExpired(true), 10000);
      timeLeftIdRef.current = setInterval(() => {
        setTimeLeft((timeLeft) => timeLeft - 1);
      }, 1000);
      lastPriceRef.current = priceRef.current;
      setOpenDrawer(true);
    }
  };

  const handleOrderValid = () => {
    if (priceRef.current > maxFund || priceRef.current < minFund) {
      setSnackbar({
        open: true,
        vertical: 'bottom',
        horizontal: 'center',
      });
    } else {
      tradeDone();
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ open: false, vertical: 'bottom', horizontal: 'center' });
  };

  async function createOrder() {
    const payload = {
      product_id: productId,
      type: 'limit',
      side: 'buy',
      size: sizeRef.current,
      fund: priceRef.current,
      payment: paymentValue,
      total_price: buyPriceRef.current[paymentValue],
    };

    const [order, statusCode] = await OrderAPI.createOrder(payload).catch(
      (error) => {
        setShowAlert(true);
        alertTitle.current = '發生錯誤';
        alertMessage.current = error.response.data.error.message;
        alertActionLabel.current = '確定';
        setDisableButton(false);
        handleExpired();
      },
    );
    if (order !== null && statusCode === 200) {
      if (order.payment === 'balance') {
        if (getCarrierCode(carrierRef.current) === 'A') {
          const invoiceDetail = {
            phoneNumber: user.phoneNumber,
            email: billInfoDataRef.current.billInfoEmail,
            address: cityRef.current + districtRef.current + addressRef.current,
          };
          order['invoiceDetail'] = invoiceDetail;
        }
        navigate('/tradeDone', { state: { order } });
      } else {
        navigate('/orderPayment', {
          state: { order: order, backNavigation: -2 },
        });
      }
      setAlert(false);
    }
    setDisableButton(false);
  }

  async function updateBillInfo() {
    let payload = {};
    let alert = null;
    let needUdate = false;
    if (invoiceTabRef.current === 1) {
      if (
        billInfoDataRef.current.doneeCode !== doneeCodeRef.current ||
        billInfoDataRef.current.isDonee !== true
      ) {
        payload = {
          donee_code: doneeCodeRef.current,
          is_donee: true,
        };
        needUdate = true;
      }
    } else {
      if (billInfoDataRef.current.billAddrCity !== cityRef.current) {
        payload['bill_addr_city'] = cityRef.current;
        needUdate = true;
      }

      if (billInfoDataRef.current.billAddrDistrict !== districtRef.current) {
        payload['bill_addr_zip_code'] = zipCodeRef.current;
        payload['bill_addr_district'] = districtRef.current;
        needUdate = true;
      }

      if (billInfoDataRef.current.billAddr !== addressRef.current) {
        payload['bill_addr'] = addressRef.current;
        needUdate = true;
      }
      if (
        billInfoDataRef.current.carrierType !==
          getCarrierCode(carrierRef.current) ||
        phoneBarcodeRef.current !== billInfoDataRef.current.phoneBarcode ||
        citizenDigitalRef.current !==
          billInfoDataRef.current.citizenDigitalCertificate
      ) {
        payload['carrier_type'] = getCarrierCode(carrierRef.current);
        if (getCarrierCode(carrierRef.current) === 'B') {
          payload['phone_barcode'] = phoneBarcodeRef.current;
        }
        if (getCarrierCode(carrierRef.current) === 'C') {
          payload['citizen_digital_certificate'] = citizenDigitalRef.current;
        }
        needUdate = true;
        payload['is_donee'] = false;
      }
    }
    if (alert) {
      alertTitle.current = '請輸入完整發票資料';
      alertMessage.current = alert;
      alertActionLabel.current = '確定';
      setShowAlert(true);
      setDisableButton(false);
    } else {
      if (needUdate) {
        await UserAPI.updateBillInfo(payload);
      }
      createOrder();
    }
  }

  async function tradeDone() {
    setDisableButton(true);
    setOpenDrawer(false);
    updateBillInfo();
  }

  const handleAlertAction = () => {
    setShowAlert(false);
  };

  const paymentType = [
    {
      title: '帳戶餘額扣款',
      subTitle: '餘額: NT$ ' + balance.current,
      payment: 'balance',
    },
    {
      title: '銀行轉帳',
      subTitle: '',
      payment: 'bank',
    },
    {
      title: '台灣大哥大門市',
      subTitle: `單筆限額 NT$${storeMaxAmountRef.current}.本月剩餘額度 NT$${storeRemainingRef.current}`,
      payment: 'store',
    },
    // {
    //     title: "大哥付",
    //     subTitle: "每筆交易限額 NT$ " + dcbMaxAmountRef.current,
    //     payment: "dcb",
    // },
  ];

  const validateOrder = () => {
    const price_int = ReplaceCommaToInt(priceRef.current);

    // 當重新輸入 size or price，符合限制條件內則根據可行的付款方式來重新開啟交易
    if (paymentBtnRef.current === 'disable') {
      if (checkPaymentEnabled('balance')) {
        paymentBtnRef.current = 'balance';
        setPaymentValue('balance');
        setDisableButton(false);
        return;
      } else if (checkPaymentEnabled('bank')) {
        paymentBtnRef.current = 'bank';
        setPaymentValue('bank');
        setDisableButton(false);
        return;
      } else if (checkPaymentEnabled('store')) {
        paymentBtnRef.current = 'store';
        setPaymentValue('store');
        setDisableButton(false);
        return;
      }
      // 如果輸入的值一直不符合限制條件，立即終止，當下次 validate 時再從頭檢驗一次
      else {
        return;
      }
    }

    // 符合下一任一條件即關閉交易
    if (
      sizeRef.current === 0 ||
      sizeRef.current < 0 ||
      price_int === 0 ||
      price_int < 0 ||
      (!checkPaymentEnabled('balance') &&
        !checkPaymentEnabled('bank') &&
        !checkPaymentEnabled('store'))
    ) {
      paymentBtnRef.current = 'disable';
      setPaymentValue('balance');
      setDisableButton(true);
    }
    // 沒有關閉交易的狀況下，輸入 size or price 的過程中或切換 size or price，如果超出限制自動切換付款方式
    else {
      if (!checkPaymentEnabled('balance')) {
        if (paymentBtnRef.current === 'balance') {
          paymentBtnRef.current = 'bank';
          setPaymentValue('bank');
        }
      } else if (!checkPaymentEnabled('bank')) {
        if (paymentBtnRef.current === 'bank') {
          paymentBtnRef.current = 'store';
          setPaymentValue('store');
        }
      } else if (!checkPaymentEnabled('store')) {
        if (paymentBtnRef.current === 'store') {
          paymentBtnRef.current = 'balance';
          setPaymentValue('balance');
        }
      }
    }
  };

  const handleSizeChange = (event) => {
    setSizeFocus(true);
    sizeFocusRef.current = true;
    setPriceFocus(false);
    priceFocusRef.current = false;
    setInvoiceInputFocus(false);
    event.target.value = CheckLeadingZero(
      ReplaceStringToNumber(event.target.value),
    );

    /*符合幣值最小位數長度*/
    /* 當比特幣數值小於時，才會觸發以下 function */
    if (event.target.value < 1) {
      // 輸入的值 !== 0 且至少有小數第一位
      if (
        event.target.value.indexOf('.') > 0 &&
        event.target.value.charAt(event.target.value.length - 1) !== '.' &&
        Number(event.target.value.charAt(event.target.value.length - 1)) !== 0
      ) {
        const roundedSize = RoundDown(
          ReplaceCommaToNumber(event.target.value), // 如果整數位部分有 `,` 則刪除
          CountDecimals(product?.baseIncrement), // 回傳小數位數
        );
        sizeRef.current = roundedSize;
        setSize(roundedSize);
      } else {
        // 值 = 0
        if (
          // 值 = 0.
          Number(event.target.value.charAt(event.target.value.length - 1)) !== 0
        ) {
          sizeRef.current = event.target.value;
        } else {
          // 值 = 0
          sizeRef.current = RoundDown(
            ReplaceCommaToNumber(event.target.value),
            CountDecimals(product?.baseIncrement),
          );
        }
        setSize(event.target.value);
      }
    } else {
      if (
        event.target.value.indexOf('.') > 0 &&
        event.target.value.charAt(event.target.value.length - 1) !== '.'
      ) {
        if (
          Number(event.target.value.charAt(event.target.value.length - 1)) !== 0
        ) {
          const roundedSize = RoundDown(
            ReplaceCommaToNumber(event.target.value),
            CountDecimals(product?.baseIncrement),
          );
          sizeRef.current = roundedSize;
          setSize(roundedSize);
        } else {
          setSize(event.target.value);
        }
      } else {
        sizeRef.current = event.target.value;
        setSize(event.target.value);
      }
    }
    priceRef.current = roundedPrice();
    setPrice(ThousandSeparator(RoundUp(priceRef.current)));
    validateOrder();
  };

  const handleTWDChange = (event) => {
    setSizeFocus(false);
    sizeFocusRef.current = false;
    setPriceFocus(true);
    priceFocusRef.current = true;
    setInvoiceInputFocus(false);
    event.target.value = CheckLeadingZero(
      ReplaceStringToInt(event.target.value),
    );
    priceRef.current = ReplaceCommaToInt(event.target.value);

    setPrice(ThousandSeparator(event.target.value));

    const newSize = roundedSize(ReplaceCommaToInt(event.target.value));

    setSize(newSize);
    sizeRef.current = newSize;

    validateOrder();
  };

  const handleReset = () => {
    clearTimeout(expiredIdRef.current);
    setExpired(false);
    clearInterval(timeLeftIdRef.current);
    setTimeLeft(10);
    paymentBtnRef.current = 'balance';
    setPaymentValue('balance');
    setSizeFocus(false);
    sizeFocusRef.current = false;
    setPriceFocus(true);
    priceFocusRef.current = true;
    setOpenDrawer(false);
    setDisableButton(false);
  };

  const handleTradeDone = () => {
    handleExpired();
    handleOrderValid();
  };

  const handlePriceFocus = () => {
    const newSize = roundedSize(ReplaceCommaToInt(priceRef.current));
    sizeRef.current = newSize;
    setSize(newSize);
    validateOrder();
  };

  const handleSizeFocus = () => {
    priceRef.current = roundedPrice();
    setPrice(ThousandSeparator(priceRef.current));
    validateOrder();
  };

  const handleExpired = () => {
    clearTimeout(expiredIdRef.current);
    setExpired(false);
    clearInterval(timeLeftIdRef.current);
    setTimeLeft(10);
    setOpenDrawer(false);
    if (websocketPriceRef.current !== 0) {
      buyPriceRef.current = websocketPriceRef.current;
    }
    if (priceFocus) {
      handlePriceFocus();
      setPriceFocus(true);
      priceFocusRef.current = true;
      setSizeFocus(false);
      sizeFocusRef.current = false;
    } else {
      handleSizeFocus();
      setSizeFocus(true);
      sizeFocusRef.current = true;
      setPriceFocus(false);
      priceFocusRef.current = false;
    }
  };

  const handleRenewPrice = () => {
    clearTimeout(expiredIdRef.current);
    setExpired(false);
    clearInterval(timeLeftIdRef.current);
    setTimeLeft(10);
    if (websocketPriceRef.current !== 0) {
      buyPriceRef.current = websocketPriceRef.current;
    }
    if (priceFocus) handlePriceFocus();
    lastPriceRef.current = priceRef.current;
    if (sizeFocus) handleSizeFocus();
    expiredIdRef.current = setTimeout(() => setExpired(true), 10000);
    timeLeftIdRef.current = setInterval(() => {
      setTimeLeft((timeLeft) => timeLeft - 1);
    }, 1000);
  };

  const roundedSize = (price, paymentType = paymentValue) => {
    const newSize = price / buyPriceRef.current[paymentType];
    return RoundDown(newSize, CountDecimals(product?.baseIncrement));
  };

  const roundedPrice = (paymentType = paymentValue) => {
    const newTWDPrice =
      ReplaceCommaToNumber(sizeRef.current) * buyPriceRef.current[paymentType];
    return RoundUp(newTWDPrice);
  };

  const handleListItemClick = (paymentItem) => {
    if (checkPaymentEnabled(paymentItem.payment)) {
      paymentBtnRef.current = paymentItem.payment;
      if (priceFocus) {
        sizeRef.current = roundedSize(priceRef.current, paymentItem.payment);
        setSize(sizeRef.current);
      }
      if (sizeFocus) {
        priceRef.current = roundedPrice(paymentItem.payment);
        setPrice(ThousandSeparator(priceRef.current));
      }
      setPaymentValue(paymentItem.payment);
    }
  };

  const handleFocus = () => {
    if (priceFocus === true) {
      handlePriceFocus();
      setPriceFocus(false);
      priceFocusRef.current = false;
      setSizeFocus(true);
      sizeFocusRef.current = true;
      validateOrder();
    }
    if (sizeFocus === true) {
      handleSizeFocus();
      setPriceFocus(true);
      priceFocusRef.current = true;
      setSizeFocus(false);
      sizeFocusRef.current = false;
      validateOrder();
    }
    if (sizeFocus === false && priceFocus === false) {
      handlePriceFocus();
      setPriceFocus(true);
      priceFocusRef.current = true;
      validateOrder();
    }
    setInvoiceInputFocus(false);
  };

  const Icon = styled('img')({
    maxHeight: 40,
    maxWidth: 40,
  });

  const sxContainer = {
    pt: `${navigationBarHeight}px`,
  };

  const handleBack = () => {
    navigate(-1);
  };

  const navigation = () => {
    return (
      <NavigationBar
        title={'購買 ' + product?.baseCurrencyId}
        leftButtonHandler={handleBack}
      />
    );
  };

  const titleLable = () => {
    return (
      <Box margin="16px">
        <Panel>
          <Grid container alignItems="center" rowSpacing={2} padding="14px">
            <Grid item xs={6}>
              <TitleLabel fontSize="13px" fontWeight="medium">
                最佳價格(餘額扣款)：
              </TitleLabel>
            </Grid>

            <Grid item xs={6}>
              <Box display="flex" alignItems="center" sx={{ float: 'right' }}>
                <TitleLabel fontSize="13px" fontWeight="regular">
                  <strong>1</strong>&nbsp;{product?.baseCurrencyId}
                  &nbsp;&nbsp;≈&nbsp;&nbsp;
                </TitleLabel>
                <TitleLabel fontSize="15px" fontWeight="bold" align="center">
                  <NumberFormat
                    value={buyPriceRef.current[paymentValue]}
                    displayType={'text'}
                    thousandSeparator={true}
                    decimalScale={
                      buyPriceRef.current[paymentValue] < 100 ? 2 : 0
                    }
                    fixedDecimalScale={true}
                  />
                </TitleLabel>
                <TitleLabel fontSize="13px" fontWeight="regular">
                  &nbsp;&nbsp;TWD
                </TitleLabel>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <TitleLabel fontSize="13px" fontWeight="regular">
                您的單筆交易金額限制：
              </TitleLabel>
            </Grid>

            <Grid item xs={6}>
              <Box display="flex" alignItems="center" sx={{ float: 'right' }}>
                <TitleLabel fontSize="15px" fontWeight="bold" align="center">
                  {product ? ThousandSeparator(minFund) : 0}
                </TitleLabel>

                <TitleLabel fontSize="13px" fontWeight="regular">
                  &nbsp;~&nbsp;
                </TitleLabel>
                <TitleLabel fontSize="15px" fontWeight="bold" align="center">
                  {product ? ThousandSeparator(maxFund) : 0}
                </TitleLabel>
                <TitleLabel fontSize="13px" fontWeight="regular">
                  &nbsp;&nbsp;TWD
                </TitleLabel>
              </Box>
            </Grid>
          </Grid>
        </Panel>
      </Box>
    );
  };

  const inputLable = (text, px, color, align) => {
    return (
      <TitleLabel fontSize={px} color={color} align={align}>
        {text}
      </TitleLabel>
    );
  };

  const inputPanel = () => {
    return (
      <Box margin="16px">
        <Panel sx={{ borderRadius: '12px' }}>
          <Box marginTop="28px">
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center">
              <Grid item xs={2}>
                {inputLable('花費', '15px', '#212529', 'right')}
              </Grid>

              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  hiddenLabel
                  autoComplete="off"
                  sx={priceFocus ? sxSelected : sxUnselected}
                  fullWidth
                  autoFocus={true}
                  inputProps={{
                    style: {
                      textAlign: 'right',
                      fontSize: 24,
                    },
                  }}
                  defaultValue={0}
                  onChange={handleTWDChange}
                  onClick={() => {
                    setSizeFocus(false);
                    sizeFocusRef.current = false;
                    setPriceFocus(true);
                    priceFocusRef.current = true;
                    setInvoiceInputFocus(false);
                  }}
                  value={price}
                />
              </Grid>

              <Grid item xs={2}>
                <IconButton disabled={true}>
                  <Icon
                    sx={{
                      maxHeight: 40,
                      maxWidth: 40,
                    }}
                    src="../images/TWD.png"
                  />
                </IconButton>
              </Grid>

              <Grid item xs={2}>
                {inputLable('TWD', '17px', '#212529', 'left')}
              </Grid>
            </Grid>
          </Box>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center">
            <Grid item xs={2}></Grid>

            <Grid item xs={6}></Grid>

            <Grid item xs={2}>
              <IconButton onClick={handleFocus}>
                <ImportExportIcon
                  style={{ fontSize: 40 }}
                  sx={{
                    color: (theme) => theme.palette.common.black,
                  }}
                />
              </IconButton>
            </Grid>

            <Grid item xs={2}></Grid>
          </Grid>
          <Box>
            <Box marginBottom="16px">
              <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="center">
                <Grid item xs={2}>
                  {inputLable('購買', '15px', '#212529', 'right')}
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    hiddenLabel
                    autoComplete="off"
                    fullWidth
                    sx={sizeFocus ? sxSelected : sxUnselected}
                    inputProps={{
                      style: {
                        textAlign: 'right',
                        fontSize: 24,
                      },
                    }}
                    // decimalScale={
                    //   product?.baseIncrement
                    //     ? CountDecimals(product?.baseIncrement)
                    //     : 0
                    // }
                    defaultValue={0}
                    onChange={handleSizeChange}
                    onClick={() => {
                      setSizeFocus(true);
                      sizeFocusRef.current = true;
                      setPriceFocus(false);
                      priceFocusRef.current = false;
                      setInvoiceInputFocus(false);
                    }}
                    value={size}
                  />
                </Grid>

                <Grid item xs={2}>
                  <IconButton disabled={true}>
                    <Icon
                      sx={{
                        maxHeight: 40,
                        maxWidth: 40,
                      }}
                      src={`/images/${product?.baseCurrencyId}.png`}
                    />
                  </IconButton>
                </Grid>

                <Grid item xs={2}>
                  {inputLable(
                    product?.baseCurrencyId,
                    '17px',
                    '#212529',
                    'left',
                  )}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Panel>
      </Box>
    );
  };

  const invoiceWording = () => {
    return (
      <>
        <SubTitleLabel>
          注意事項：
          <br />
          1. 下單成立後無法退款
          <br />
          2. 依法規定，個人發票一經開立，不得更改。
          <br />
        </SubTitleLabel>
        <SubTitleLabel>
          <span style={{ color: '#FF8F08', cursor: 'pointer' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;財政部電子發票整合服務平台
          </span>
        </SubTitleLabel>
      </>
    );
  };

  const titleLabel = (title) => {
    return (
      <Box marginTop="26px" marginLeft="22px" marginBottom="16px">
        <TitleLabel fontSize="18px" color="#070707">
          {title}
        </TitleLabel>
      </Box>
    );
  };

  const paymentTitleLabel = () => {
    return (
      <Box display="flex" justifyContent="space-between" marginRight="16px">
        {titleLabel('付款方式＊')}
        {priceFocus && titleLabel('購買')}
        {sizeFocus && titleLabel('花費')}
      </Box>
    );
  };

  const selectTitleLable = (item) => {
    return (
      <TitleLabel fontSize="17px" fontWeight="medium" sx={opacitySx(item)}>
        {item.title}
      </TitleLabel>
    );
  };

  const selectDescLable = (item) => {
    const words = item.subTitle.split('.');
    return (
      <Box display="flex" flexDirection="column">
        <TitleLabel fontSize="13px" color="#6C757D" sx={opacitySx(item)}>
          {words[0]}
        </TitleLabel>
        {words[1] && (
          <TitleLabel fontSize="13px" color="#6C757D" sx={opacitySx(item)}>
            {words[1]}
          </TitleLabel>
        )}
        <TitleLabel fontSize="13px" color="#ff8f08">
          {getWording(item.payment)}
        </TitleLabel>
      </Box>
    );
  };

  const getPaymentNavigateItem = (paymentItem) => {
    if (paymentItem.payment === 'balance') {
      if (getWording('balance') === '餘額不足') {
        // 帳戶餘額扣款
        return (
          <StyledBox onClick={() => setShowDialog(true)}>
            <Label fontSize="13px" color="#ffffff" fontWeight="bold">
              儲值
            </Label>
          </StyledBox>
        );
      }
    }

    if (paymentItem.payment === 'bank') {
      if (!getWording('bank')) {
        // 銀行轉帳
        if (user?.bankAccount?.status === 'verifing') {
          return (
            // 銀行帳戶審核中
            <StyledBox
              onClick={() =>
                navigate('/bankStatus', {
                  state: { backNavigation: -1 },
                })
              }>
              <Label fontSize="13px" color="ffffff" fontWeight="bold">
                審核中
              </Label>
            </StyledBox>
          );
        } else if (
          user?.bankAccount?.status === 'unverified' ||
          user?.bankAccount?.status === 'failed'
        ) {
          // 銀行帳戶未審核 / 審核失敗
          return (
            <StyledBox onClick={() => navigate('/bankAccount')}>
              <Label fontSize="13px" color="#ffffff" fontWeight="bold">
                綁定
              </Label>
            </StyledBox>
          );
        }
      }
    }

    return (
      <Radio
        checked={paymentBtnRef.current === paymentItem.payment}
        value={paymentItem.payment}
        name={paymentItem.payment}
        sx={opacitySx(paymentItem)}
      />
    );
  };

  const abbrFund = (number) => {
    const stringifyNumber = number.toString();
    if (stringifyNumber.length > 4) {
      return `${stringifyNumber.slice(0, -4)} 萬`;
    } else return stringifyNumber;
  };

  const abbrSizePrice = (number) => {
    const stringifyNumber = number.toString();

    return `${stringifyNumber.slice(0, 10)}`;
  };

  const getWording = (paymentItem) => {
    const price_int = ReplaceCommaToInt(priceRef.current);
    const balance_int = ReplaceCommaToInt(balance.current);
    const storeMaxAmount_int = ReplaceCommaToInt(storeMaxAmountRef.current);
    const storeRemainingAmount_int = ReplaceCommaToInt(
      storeRemainingRef.current,
    );
    const dcbMaxAmount_int = ReplaceCommaToInt(dcbMaxAmountRef.current);

    if (
      price_int > maxFund &&
      roundedPrice('store') > maxFund &&
      roundedPrice('bank') > maxFund
    ) {
      return `超出單筆交易上限NT$ ${abbrFund(maxFund)}`;
    }

    if (
      price_int < minFund &&
      roundedPrice('store') < minFund &&
      roundedPrice('bank') < minFund
    ) {
      return `低於單筆交易下限NT$ ${abbrFund(minFund)}`;
    }

    if (paymentItem === 'balance') {
      let basedPrice = '';
      let wording = '';
      if (sizeFocusRef.current) basedPrice = roundedPrice('balance');
      if (priceFocusRef.current) basedPrice = price_int;

      if (basedPrice < minFund) {
        wording = `低於單筆交易下限NT$ ${abbrFund(minFund)}`;
      } else if (basedPrice > maxFund) {
        wording = `超出單筆交易上限NT$ ${abbrFund(maxFund)}`;
      } else if (basedPrice > balance_int && balance_int > 0) {
        // 帳戶餘額扣款
        wording = '餘額不足';
      }
      return wording;
    }

    if (paymentItem === 'bank') {
      let basedPrice = '';
      let wording = '';
      if (sizeFocusRef.current) basedPrice = roundedPrice('bank');
      if (priceFocusRef.current) basedPrice = price_int;

      if (basedPrice < minFund) {
        wording = `低於單筆交易下限NT$ ${abbrFund(minFund)}`;
      } else if (basedPrice > maxFund) {
        wording = `超出單筆交易上限NT$ ${abbrFund(maxFund)}`;
      }
      return wording;
    }

    if (paymentItem === 'store') {
      let basedPrice = '';
      let wording = '';
      if (sizeFocusRef.current) basedPrice = roundedPrice('store');
      if (priceFocusRef.current) basedPrice = price_int;

      if (basedPrice < minFund) {
        wording = `低於單筆交易下限NT$ ${abbrFund(minFund)}`;
      } else if (basedPrice > maxFund) {
        wording = `超出單筆交易上限NT$ ${abbrFund(maxFund)}`;
      } else if (basedPrice > storeMaxAmount_int) {
        wording = '超出限額';
      } else if (basedPrice > storeRemainingAmount_int) {
        wording = '本月剩餘額度不足';
      }
      return wording;
    }

    if (paymentItem === 'dcb') {
      let basedPrice = '';
      let wording = '';
      if (sizeFocusRef.current) basedPrice = roundedPrice('dcb');
      if (priceFocusRef.current) basedPrice = price_int;

      if (basedPrice < minFund) {
        wording = `低於單筆交易下限NT$ ${abbrFund(minFund)}`;
      } else if (basedPrice > maxFund) {
        wording = `超出單筆交易上限NT$ ${abbrFund(maxFund)}`;
      } else if (basedPrice > dcbMaxAmount_int) {
        wording = '超出限額';
      }
      return wording;
    }
    return false;
  };

  const checkPaymentEnabled = (payment) => {
    if (payment === 'balance') {
      if (!balanceAvailableRef.current) return false;
      if (getWording(payment)) return false;
    }
    if (payment === 'bank') {
      if (!bankAvailableRef.current) return false;
      if (getWording(payment)) return false;
    }

    if (payment === 'store') {
      if (!storeAvailableRef.current) return false;
      if (getWording(payment)) return false;
    }

    if (payment === 'dcb') {
      if (dcbAvailableRef.current) return false;
      if (getWording(payment)) return false;
    }

    return true;
  };

  const opacitySx = (paymentItem) => {
    return {
      opacity: `${checkPaymentEnabled(paymentItem.payment) ? '1' : '0.3'}`,
    };
  };

  const selectPayment = () => {
    return (
      <Box marginRight="16px" marginLeft="16px" marginBottom="24px">
        <Panel>
          <List
            sx={{
              width: '100%',
              bgcolor: 'background.paper',
            }}>
            {paymentType.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <Grid
                    container
                    key={index}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    onClick={() => handleListItemClick(item)}>
                    <Grid item>
                      <ListItem
                        sx={{
                          height: `${
                            index === paymentType.length - 1 ? '100px' : '70px'
                          }`,
                        }}>
                        {getPaymentNavigateItem(item)}
                        <ListItemText
                          primary={selectTitleLable(item)}
                          secondary={selectDescLable(item)}
                        />
                      </ListItem>
                    </Grid>
                    <Grid item>
                      <Box
                        display="flex"
                        flexDirection="column"
                        marginRight="16px">
                        <>
                          {priceFocus && product && (
                            <TitleLabel
                              fontSize="12px"
                              color={index === 0 ? '#21BF73' : ''}
                              marginLeft="auto">
                              {abbrSizePrice(
                                roundedSize(priceRef.current, item.payment),
                              )}
                              &nbsp;
                              {product.baseCurrencyId}
                            </TitleLabel>
                          )}
                          {sizeFocus && (
                            <TitleLabel
                              fontSize="12px"
                              color={index === 0 ? '#21BF73' : ''}
                              marginLeft="auto">
                              NT$&nbsp;
                              <PriceNumberFormat
                                value={abbrSizePrice(
                                  roundedPrice(item.payment),
                                )}
                              />
                            </TitleLabel>
                          )}
                          {index === 0 && product && (
                            <SuccessStatusBox marginLeft="auto">
                              <SuccessLabel>最佳價格</SuccessLabel>
                            </SuccessStatusBox>
                          )}
                        </>
                      </Box>
                    </Grid>
                  </Grid>

                  {index < paymentType.length - 1 && (
                    <Divider variant="middle" component="li" />
                  )}
                </React.Fragment>
              );
            })}
          </List>
        </Panel>
      </Box>
    );
  };

  const sxBottomDrawer = {
    bottom: 0,
    left: 0,
    right: 0,
    padding: '16px',
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
  };

  const bottomPanel = () => {
    return (
      <BottomPanel>
        <StyledButton
          variant="contained"
          fullWidth
          disabled={disableButton}
          disableElevation
          onClick={checkBillInfo}>
          購買&nbsp;
          <NumberFormat
            value={size}
            displayType={'text'}
            thousandSeparator={true}
            decimalScale={
              product?.baseIncrement ? CountDecimals(product?.baseIncrement) : 0
            }
          />
          &nbsp;{product?.baseCurrencyId}
        </StyledButton>
      </BottomPanel>
    );
  };

  const bottomDrawer = () => {
    return (
      <Box marginTop="23px">
        <Drawer
          open={openDrawer}
          onClose={handleExpired}
          anchor="bottom"
          PaperProps={{
            elevation: 0,
            style: { backgroundColor: 'transparent' },
          }}>
          <Paper sx={sxBottomDrawer} elevation={3}>
            <Box display="flex" alignItems="center">
              <Box sx={{ flex: 1 }} marginLeft="10vw">
                <TitleLabel
                  fontSize="17px"
                  fontWeight="bold"
                  textAlign="center">
                  確認交易資訊
                </TitleLabel>
              </Box>
              <IconButton onClick={handleExpired}>
                <CloseIcon
                  style={{ fontSize: 24 }}
                  sx={{
                    color: (theme) => theme.palette.common.black,
                  }}
                />
              </IconButton>
            </Box>
            {bottomDrawerTitle.map((item, index) => {
              return (
                <Box
                  key={item.title}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  marginTop={`${index === 0 ? '24px' : '8px'}`}>
                  <TitleLabel fontSize="15px">{item.title}</TitleLabel>
                  {item.value}
                </Box>
              );
            })}
            <TitleLabel
              fontSize="13px"
              color="#696969"
              sx={{ marginTop: '12px' }}>
              {purchaseStatements[paymentValue]}
            </TitleLabel>

            {!expired && (
              <TitleLabel
                fontSize="13px"
                color="#FF8F08"
                sx={{ marginTop: '12px', textAlign: 'center' }}>
                價格有效時間 {timeLeft}
              </TitleLabel>
            )}

            <Box display="flex" marginTop="6px">
              {!expired ? (
                <>
                  <StyledButton
                    variant="contained"
                    style={{
                      backgroundColor: '#FFFFFF',
                      color: '#000000',
                    }}
                    fullWidth
                    sx={{
                      height: '48px',
                    }}
                    onClick={handleReset}>
                    重新選擇
                  </StyledButton>
                  <Box width="20px"></Box>
                  <StyledButton
                    variant="contained"
                    fullWidth
                    disableElevation
                    disabled={disableButton}
                    onClick={handleTradeDone}>
                    確認
                  </StyledButton>
                </>
              ) : (
                <StyledButton
                  variant="contained"
                  fullWidth
                  disableElevation
                  onClick={handleRenewPrice}
                  sx={{
                    height: '48px',
                  }}>
                  報價已失效，重新獲得報價
                </StyledButton>
              )}
            </Box>
          </Paper>
        </Drawer>
      </Box>
    );
  };

  const depositDialog = () => {
    return (
      <Dialog
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        title="台幣儲值"
        message="請選擇想要的台幣儲值方式"
        actionLabel="台灣大哥大門市"
        secondaryActionLabel="銀行轉帳"
        actionHandler={() => {
          navigate('/deposit/store');
        }}
        secondaryActionHandler={() => {
          if (user?.bankAccount?.status === 'succeed') {
            navigate('/deposit/bank');
          } else if (
            user?.bankAccount?.status === 'failed' ||
            user?.bankAccount?.status === 'verifing'
          ) {
            navigate('/bankStatus', {
              state: { backNavigation: -1 },
            });
          } else {
            navigate('/bankAccount');
          }
        }}
      />
    );
  };

  return (
    <>
      <Box sx={sxContainer}>
        {alert ? (
          <Alert severity="error">Something Wrong, please try again.</Alert>
        ) : (
          <></>
        )}
        {navigation() /* navigation bar */}
        {titleLable() /* 幣值 */}
        {inputPanel() /* 輸入購買幣值 */}
        {paymentTitleLabel() /* 發票類型標題 */}
        {selectPayment() /* 選擇付款方式 */}
        {titleLabel('發票類型*') /* 發票類型標題 */}
        <Invoice
          carrierRef={carrierRef}
          phoneBarcodeRef={phoneBarcodeRef}
          citizenDigitalRef={citizenDigitalRef}
          zipCodeRef={zipCodeRef}
          cityRef={cityRef}
          districtRef={districtRef}
          addressRef={addressRef}
          doneeCodeRef={doneeCodeRef}
          doneeRef={doneeRef}
          invoiceTabRef={invoiceTabRef}
          setTab={setTab}
          billNameRef={billNameRef}
          invoiceInputFocus={invoiceInputFocus}
          setInvoiceInputFocus={setInvoiceInputFocus}
        />
        <Box marginLeft="16px" marginBottom="90px">
          {invoiceWording()}
          {bottomPanel() /* 購買 / 賣出按鈕 */}
          {bottomDrawer() /* drawer */}
        </Box>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleCloseSnackbar}
          message="交易金額超過或低於額度，請重新確認購買金額與數量"
          key={vertical + horizontal}
          autoHideDuration={3000}
          sx={{ margin: '0 16px 90px' }}
        />
      </Box>
      <Dialog
        showDialog={showAlert}
        setShowDialog={setShowAlert}
        title={alertTitle.current}
        message={alertMessage.current}
        actionLabel={alertActionLabel.current}
        actionHandler={handleAlertAction}
      />
      {depositDialog() /* 台幣儲值 */}
    </>
  );
}
